import * as React from 'react'

function Phone() {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='#ffffff'
        d='M24.954 21.786V3.66A3.66 3.66 0 0021.292 0H8.318a3.66 3.66 0 00-3.66 3.66v18.126zM14.806 2.281a1.1 1.1 0 11-1.1 1.1 1.1 1.1 0 011.1-1.1'
        transform='matrix(.96063 0 0 .96063 .777 .618)'
      />
      <path
        fill='#ffffff'
        d='M4.658 23.982v2.3a3.66 3.66 0 003.66 3.66h12.974a3.66 3.66 0 003.66-3.66v-2.3zm10.148 3.928a1.1 1.1 0 111.1-1.1 1.1 1.1 0 01-1.1 1.1'
        transform='matrix(.96063 0 0 .96063 .777 .618)'
      />
    </svg>
  )
}

export default Phone
