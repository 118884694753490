import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { TeamMemberData } from '../../pages/team'
import * as style from './team_member.module.scss'

function TeamMember({ fullName, position, linkedin }: Omit<TeamMemberData, 'id'>): React.ReactElement {
  return (
    <div className={style.member}>
      <StaticImage src='../../images/member.jpg' alt={fullName} className={style.image} />
      <div className={style.name}>
        <div className={style.icon}>
          <svg xmlns='http://www.w3.org/2000/svg' width='28' height='41' viewBox='0 0 28 41'>
            <rect
              id='Rectangle_9'
              data-name='Rectangle 9'
              width='28'
              height='22'
              rx='11'
              transform='translate(0 19)'
              fill='#ededed'
            />
            <ellipse
              id='Ellipse_9'
              data-name='Ellipse 9'
              cx='8.5'
              cy='9.5'
              rx='8.5'
              ry='9.5'
              transform='translate(6)'
              fill='#ededed'
            />
          </svg>
        </div>
        <span>{fullName}</span>
      </div>
      <div className={style.position}>{position}</div>
      <div className={style.contacts}>
        <a target='_blank' rel='noreferrer' href={linkedin} title={`${fullName} linkedin`}>
          <StaticImage width={30} height={30} src='../Contact/linkedin.png' alt={`${fullName} linkedin`} />
        </a>
      </div>
    </div>
  )
}

export default TeamMember
