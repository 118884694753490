import * as React from 'react'
import type { HeadFC } from 'gatsby'
import Contact from '../components/Contact'
import MainLayout from '../Layouts/MainLayout'
import { SEO } from '../components/seo'
import Container from '../components/Container'
import * as style from '../scss/team.module.scss'
import TeamMember from '../components/TeamMember'

export interface TeamMemberData {
  image: string
  id: string
  fullName: string
  position: string
  linkedin: string
}

const TeamPage: React.FC = () => {
  const members: TeamMemberData[] = [
    {
      id: '3a4c56ae-e7e2-4688-88e2-a4622b005a66',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
    {
      id: '3512e8ef-9fec-4935-8f52-cc3cdeee545a',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
    {
      id: '192af944-8d4f-4a33-b759-fe70b1020edc',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
    {
      id: 'bd6b6bec-9e0c-4f59-8b86-06172e44939f',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
    {
      id: '422c1df3-5931-4bbf-b4f5-3e142e341941',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
    {
      id: '9acb47cb-1f06-456e-8ef2-c820b6fa71ec',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
    {
      id: '2ccf61e6-1a6b-4cd0-8db8-e270010c02d5',
      image: '',
      fullName: 'Linda Kochbati',
      position: 'CEO',
      linkedin: 'https://www.linkedin.com/in/linda-kochbati/',
    },
  ]
  return (
    <MainLayout>
      <main className={style.page}>
        <div className={style.hero}>
          <Container>
            <h1>Our team</h1>
          </Container>
        </div>
        <Container>
          <div className={style.teams}>
            {members.map(({ id, ...teamMember }) => (
              <TeamMember key={id} {...teamMember} />
            ))}
          </div>
        </Container>
      </main>
      <Contact />
    </MainLayout>
  )
}

export default TeamPage

export const Head: HeadFC = () => {
  return <SEO title='Our team' />
}
