import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import Container from '../Container'
import * as style from './contact.module.scss'
import Phone from './icons/Phone'
import Mail from './icons/Mail'
import { Link, graphql, useStaticQuery } from 'gatsby'

interface StaticQueryData {
  site: {
    siteMetadata: {
      phone: string
      email: string
    }
  }
}

const Contact: React.FC = () => {
  const {
    site: {
      siteMetadata: { phone, email },
    },
  } = useStaticQuery<StaticQueryData>(graphql`
    query {
      site {
        siteMetadata {
          phone
          email
        }
      }
    }
  `)

  return (
    <section id='homepage-Contact' className={style.contact}>
      <Container>
        <div className={style.wrapper}>
          <div className={style.contactData}>
            <h2>Contact US</h2>
            <div className={style.iconContainer}>
              <Phone />
              <p>{phone}</p>
            </div>
            <div className={style.iconContainer}>
              <Mail />
              <p>{email}</p>
            </div>
          </div>
          <div className={style.blog}>
            <Link className={style.title} to='/blog'>
              NeuroBlog&apos;s
            </Link>
            <div className={style.socialMedias}>
              <a href='https://www.facebook.com/Intelligence.Artificielle.Neuro' rel='noreferrer' target='_blank'>
                <StaticImage className={style.linkImage} src='./facebook.png' alt='facebook' />
              </a>
              <a href='//twitter.com' rel='noreferrer' target='_blank'>
                <StaticImage className={style.linkImage} src='./twitter.png' alt='Twitter' />
              </a>
              <a href='//linkedin.com' rel='noreferrer' target='_blank'>
                <StaticImage className={style.linkImage} src='./linkedin.png' alt='linkedin' />
              </a>
              <a href='//youtube.com' rel='noreferrer' target='_blank'>
                <StaticImage className={style.linkImage} src='./youtube.png' alt='youtube' />
              </a>
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

export default Contact
