import * as React from 'react'

function Phone() {
  return (
    <svg width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
      <path
        fill='#ffffff'
        d='M2.205 95.257l10.289 11.675a3.082 3.082 0 004.625 0L27.23 95.46a1.083 1.083 0 01.286-.227 3.478 3.478 0 00-1.4-.293H3.494a3.5 3.5 0 00-1.342.268c.018.018.037.031.053.049'
        transform='matrix(.9338 0 0 .9338 1.173 -84.298)'
      />
      <path
        fill='#ffffff'
        d='M29.074 96.578a1.076 1.076 0 01-.2.333l-10.109 11.472a5.278 5.278 0 01-7.918 0L.557 96.709c-.021-.023-.035-.05-.054-.074a3.468 3.468 0 00-.5 1.8v15.8a3.5 3.5 0 003.494 3.5h22.62a3.5 3.5 0 003.494-3.5v-15.8a3.47 3.47 0 00-.537-1.856'
        transform='matrix(.9338 0 0 .9338 1.173 -84.298)'
      />
    </svg>
  )
}

export default Phone
